import React from "react";

export default function Teenpatti1Day({ data }) {
  const resultDTO = data?.casinoIndividualResultDTO || data;

  const casinoIndividualResultMaps =
    resultDTO?.casinoIndividualResultMaps || [];
  const casinoCardResultMaps = resultDTO?.casinoCardResultMaps || [];

  const getResultByType = (resultFor) => {
    const resultObj = casinoIndividualResultMaps.find(
      (item) => item.resultFor === resultFor
    );
    return resultObj ? resultObj.result[0] : "";
  };

  const getCardsByPlayer = (player) => {
    return casinoCardResultMaps.find((item) => item.resultFor === player) || {};
  };

  const matchTime = resultDTO?.createdAt
    ? new Date(resultDTO.createdAt + "Z").toLocaleString()
    : "";

  const players = ["Player A", "Player B"];

  // Helper function to determine if a card number is odd or even
  const isOdd = (card) => {
    const number = card.replace(/[HDSC]/g, "");
    if (["J", "Q", "K"].includes(number)) return false; // Face cards are even
    if (number === "A") return true; // Ace is odd
    return parseInt(number) % 2 !== 0;
  };

  // Calculate Odd/Even string for all cards
  const calculateOddEven = () => {
    const allCards = [
      ...(getCardsByPlayer("Player A").result || []),
      ...(getCardsByPlayer("Player B").result || []),
    ];

    return allCards.map((card) => (isOdd(card) ? "Odd" : "Even")).join(" ");
  };

  // Check if cards are consecutive for a player
  const checkConsecutive = (cards) => {
    if (!cards || cards.length === 0) return "No";

    const getCardValue = (card) => {
      const number = card.replace(/[HDSC]/g, "");
      const values = {
        A: 1,
        J: 11,
        Q: 12,
        K: 13,
      };
      return values[number] || parseInt(number);
    };

    const sortedValues = cards.map(getCardValue).sort((a, b) => a - b);

    for (let i = 1; i < sortedValues.length; i++) {
      if (sortedValues[i] !== sortedValues[i - 1] + 1) {
        return "No";
      }
    }
    return "Yes";
  };

  // Calculate consecutive string
  const calculateConsecutive = () => {
    const playerACards = getCardsByPlayer("Player A").result || [];
    const playerBCards = getCardsByPlayer("Player B").result || [];

    return `A : ${checkConsecutive(playerACards)} | B : ${checkConsecutive(
      playerBCards
    )}`;
  };

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        <div className="casino-result-round-id">
          <span>
            <b>Round Id: </b> {resultDTO?.marketId || ""}
          </span>
          <span>
            <b>Match Time: </b>
            {matchTime}
          </span>
        </div>
        <div className="row mt-2">
          {players.map((player) => {
            const playerData = getCardsByPlayer(player);
            return (
              <div key={player} className="col-md-6 text-center">
                <h4 className="result-title">{player}</h4>
                <div className="casino-result-cards">
                  {playerData.result?.map((card, index) => (
                    <img
                      key={index}
                      src={`https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/${card}.png`}
                      alt={`${player} Card ${index + 1}`}
                    />
                  ))}
                  {playerData.isWinning && (
                    <div className="casino-winner-icon">
                      <i className="fas fa-trophy" />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              <div className="casino-result-desc-item">
                <div>Winner:</div>
                <div>{getResultByType("Winner")}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Odd/Even:</div>
                <div>{calculateOddEven()}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Consecutive:</div>
                <div>{calculateConsecutive()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
