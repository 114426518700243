import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import CasinoPage from "./components/CasinoPage";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
export default function Poker20() {
  const GAME_ID = "POKER_20";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.poker2020}
      GAME_ID={GAME_ID}
      shortName={" poker20"}
      gameName={"20-20 Poker"}
    >
      <div className="casino-table">
        <div className="poker20-other-odds">
          <div className="casino-table-box">
            <div className="casino-table-left-box">
              <div className="w-100 d-xl-none mobile-nation-name">
                Player A
              </div>
              {[
                "Winner",
                "One Pair",
                "Two Pair",
                "Three of a Kind",
                "Straight",
                "Flush",
                "Full House",
                "Four of a Kind",
                "Straight Flush",
              ]?.map((item) => (
                <div
                  onClick={() => {
                    setBet(item + " Player A", casinoData, dispatch);
                  }}
                  className="casino-odds-box-container"
                >
                  <div className="casino-nation-name text-center">{item}</div>
                  <div
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <span className="casino-odds">
                      {getOddForEntity(item + " Player A", casinoData, true)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="casino-table-box-divider" />
            <div className="casino-table-right-box">
              <div className="w-100 d-xl-none mobile-nation-name">
                Player B
              </div>
              {[
                "Winner",
                "One Pair",
                "Two Pair",
                "Three of a Kind",
                "Straight",
                "Flush",
                "Full House",
                "Four of a Kind",
                "Straight Flush",
              ]?.map((item) => (
                <div
                  onClick={() => {
                    setBet(item + " Player B", casinoData, dispatch);
                  }}
                  className="casino-odds-box-container"
                >
                  <div className="casino-nation-name text-center">{item}</div>
                  <div
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <span className="casino-odds">
                      {getOddForEntity(item + " Player B", casinoData, true)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
