import React, { useEffect, useState } from "react";

import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import CasinoPage from "./components/CasinoPage";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import "./index.scoped.css";
export default function Card32B() {
  const GAME_ID = "CASINO_METER";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.casinoMeter}
      GAME_ID={GAME_ID}
      shortName={"cmeter"}
      gameName={"Casino Meter"}
    >
      <div className="casino-detail">
        <div className="casino-table">
          <div className="casino-table-full-box">
            <div className="cmeter-video-cards-box">
              <div className="cmeter-low">
                <div>
                  <span className="text-fancy">Low</span>
                  <span className="ms-2 text-success">
                    <b>
                      {" "}
                      {casinoData?.data?.response?.cards[0] &&
                        casinoData?.data?.response?.cards[0]?.length > 0 &&
                        casinoData?.data?.response?.cards[0]?.reduce(
                          (acc, item) => {
                            let val = 0;
                            val = item.slice(0, 1);
                            if (
                              val !== "A" &&
                              val !== "J" &&
                              val !== "Q" &&
                              val !== "K"
                            ) {
                              return parseInt(acc) + parseInt(val);
                            } else {
                              if (val == "A") {
                                val = 1;
                              } else if (val == "J") {
                                val = 11;
                              } else if (val == "Q") {
                                val = 12;
                              } else if (val == "K") {
                                val = 13;
                              }
                              return parseInt(acc) + parseInt(val);
                            }
                          }
                        )}
                    </b>
                  </span>
                </div>
                <div className="cmeter-low-cards">
                  {casinoData?.data?.response?.cards[0]?.map(
                    (item, index) => (
                      <img
                        src={`https://versionobj.ecoassetsservice.com/v25/static/front/img/cards/${item}.png`}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="cmeter-high">
                <div>
                  <span className="text-fancy">High</span>
                  <span
                    style={{
                      color: "white !important",
                    }}
                    className="ms-2 "
                  >
                    <b>
                      {" "}
                      {casinoData?.data?.response?.cards[1] &&
                        casinoData?.data?.response?.cards[1]?.length > 0 &&
                        casinoData?.data?.response?.cards[1]?.reduce(
                          (acc, item) => {
                            let val = 0;
                            val = item.slice(0, 1);
                            if (
                              val !== "A" &&
                              val !== "J" &&
                              val !== "Q" &&
                              val !== "K"
                            ) {
                              return parseInt(acc) + parseInt(val);
                            } else {
                              if (val == "A") {
                                val = 1;
                              } else if (val == "J") {
                                val = 11;
                              } else if (val == "Q") {
                                val = 12;
                              } else if (val == "K") {
                                val = 13;
                              }
                              return parseInt(acc) + parseInt(val);
                            }
                          }
                        )}
                    </b>
                  </span>
                </div>
                <div className="cmeter-high-cards">
                  {casinoData?.data?.response?.cards[1]?.map(
                    (item, index) => (
                      <img
                        src={`https://versionobj.ecoassetsservice.com/v25/static/front/img/cards/${item}.png`}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="casino-table-box mt-3">
            <div
              className={`casino-table-left-box ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
            >
              <div className=" text-center">
                <b className="text-info">Low</b>
              </div>
              <div className="cmeter-card-box mt-2">
                {["A", "2", "3", "4", "5", "6", "7", "8", "9"]?.map(
                  (item) => (
                    <div
                      onClick={
                        casinoData?.data?.response?.autoTime < 5
                          ? () => {}
                          : () => {
                              setBet("Low", casinoData, dispatch);
                            }
                      }
                      className="card-odd-box"
                    >
                      <div className="">
                        <img
                          src={`https://versionobj.ecoassetsservice.com/v25/static/front/img/cards/${item}.png`}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="casino-nation-book text-center mt-2" />
            </div>
            <div
              className={`casino-table-right-box ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
            >
              <div className=" text-center">
                <b className="text-info">High</b>
              </div>
              <div className="cmeter-card-box mt-2">
                {["10", "J", "Q", "K"]?.map((item) => (
                  <div
                    onClick={
                      casinoData?.data?.response?.autoTime < 5
                        ? () => {}
                        : () => {
                            setBet("High", casinoData, dispatch);
                          }
                    }
                    className="card-odd-box"
                  >
                    <div className="">
                      <img
                        src={`https://versionobj.ecoassetsservice.com/v25/static/front/img/cards/${item}.png`}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="casino-nation-book text-center mt-2" />
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
