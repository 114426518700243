import React from "react";
import Teen20 from "./resultModals/Teen20";
import TeenpattiOpen from "./resultModals/TeenpattiOpen";
import Teen9 from "./resultModals/Teen9";
import Teenpatti1Day from "./resultModals/Teenpatti1Day";
import SuperOver from "./resultModals/SuperOver";
import Race20 from "./resultModals/Race20";
import AB2 from "./resultModals/AndarBahar2";
import DragonTiger2020 from "./resultModals/2020DragonTiger";
import Baccarat from "./resultModals/Baccarat";
import Baccarat2 from "./resultModals/Baccarat2";
import Lucky7B from "./resultModals/Lucky7B";
import CasinoWar from "./resultModals/CasinoWar";
import Cmeter from "./resultModals/Cmeter";
import Card32 from "./resultModals/Card32";
import Card32B from "./resultModals/Card32B";
import Queen from "./resultModals/Queen";
import Dt1day from "./resultModals/Dt1day";
import Poker2020 from "./resultModals/Poker2020";
import InstantWorli from "./resultModals/InstantWorli";
import AAA from "./resultModals/AAA";
import BTable from "./resultModals/BTable";
import AndarBahar from "./resultModals/AndarBahar";
import Cricket20 from "./resultModals/Cricket20";
import Lucky7Aa from "./resultModals/Lucky7A";
import moment from "moment/moment";

export default function GenericIndividualResult({
  data,
  shortName,
  onClose,
  GAME_ID,
}) {
  console.log("shortName", shortName);
  console.log("GAME_ID", GAME_ID);
  const renderResult = () => {
    switch (shortName || GAME_ID) {
      case "teenpatti20":
        return <Teen20 data={data} />;
      case "teenpattiopen":
        return <TeenpattiOpen data={data} />;
      case "teenpattitest":
        return <Teen9 data={data} />;
      case "teenpatti1day":
        return <Teenpatti1Day data={data} />;
      case "super-over":
        return <SuperOver data={data} />;
      case "race20":
        return <Race20 data={data} />;
      case "ab2":
        return <AB2 data={data} />;
      case "dt20":
        return <DragonTiger2020 data={data} />;
      case "baccarat":
        return <Baccarat data={data} />;
      case "baccarat baccarat2game":
        return <Baccarat2 data={data} />;
      // case "lucky7acards":
      //   return <Lucky7B data={data} />;
      case "casino-war":
        return <CasinoWar data={data} />;
      case "cmeter":
        return <Cmeter data={data} />;
      case "cards32a":
        return <Card32 data={data} />;
      case "cards32a cards32b":
        return <Card32B data={data} />;
      case "queen":
        return <Queen data={data} />;
      case "dt1day":
        return <Dt1day data={data} />;
      case "cards32a poker-20":
        return <Poker2020 data={data} />;
      case "worli":
        return <InstantWorli data={data} />;
      case "bollywood":
        return <BTable data={data} />;
      case "aaa":
        return <AAA data={data} />;
      case "ab ab3":
        return <AndarBahar data={data} />;
      case "cricket20":
        return <Cricket20 data={data} />;

      case "lucky7acards":
        // Differentiate by GAME_ID
        if (GAME_ID === "LUCKY7") {
          return <Lucky7Aa data={data} />;
        } else {
          return <Lucky7B data={data} />;
        }

      default:
        return renderDefaultResult();
    }
  };
  // if (GAME_ID === "LUCKY7") {
  //   return <Lucky7Aa data={data} />;
  // }

  const renderDefaultResult = () => {
    return (
      <div className="modal-body">
        <h1>Hello</h1>
      </div>
    );
  };

  const getModalTitle = () => {
    switch (shortName || GAME_ID) {
      case "teenpatti20":
        return "Teen Patti Result";
      case "teenpattiopen":
        return "Teenpatti Open Result";
      case "teenpattitest":
        return "Teenpatti Test Result";
      case "teenpatti1day":
        return "Teenpatti 1 Day Result";
      case "super-over":
        return "Super Over";
      case "race20":
        return "Race 20";
      case "ab2":
        return "Andar Bahar 2";
      case "dt20":
        return "2020 DRAGON TIGER 2";
      case "baccarat":
        return "Baccarat";
      case "baccarat baccarat2game":
        return "Baccarat2";
      // case "lucky7acards":
      //   return "Lucky 7 - B";
      case "casino-war":
        return "CasinoWar";
      case "cmeter":
        return "Casino Meter";
      case "cards32a":
        return "Card 32";
      case "cards32a cards32b":
        return "Card 32 B";
      case "queen":
        return "Casino Queen";
      case "dt1day":
        return "Dragon Tiger 1 Day";
      case "cards32a poker-20":
        return "Poker 2020";
      case "worli":
        return "Instant Worli";
      case "aaa":
        return "Amar Akbar Anthony";
      case "bollywood":
        return "Bollywood Casino";
      case "ab ab3":
        return "Andar Bahar";
      case "cricket20":
        return "Cricket Match 20";
      case "LUCKY7":
        return "Lucky 7 - A";

      case "lucky7acards":
        // Differentiate by GAME_ID
        if (GAME_ID === "LUCKY7") {
          return "Lucky 7 - A";
        } else {
          return "Lucky 7 - B";
        }
      default:
        return "Game Result";
    }
  };
  // if (GAME_ID === "LUCKY7") {
  //   return "Lucky 7 - A";
  // }

  return (
    <div
      role="dialog"
      aria-modal="true"
      className="fade modal show"
      tabIndex={-1}
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">{getModalTitle()}</div>
            <button
              onClick={onClose}
              type="button"
              className="btn-close"
              aria-label="Close"
            />
          </div>
          {renderResult()}
          <div className="row mt-4 justify-content-between">
            <div className="col-md-6">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="all"
                  name="filter"
                  defaultValue="all"
                  defaultChecked=""
                />
                All
                <label className="form-check-label" htmlFor="all" />
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="back"
                  name="filter"
                  defaultValue="all"
                />
                Back
                <label className="form-check-label" htmlFor="back" />
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="lay"
                  name="filter"
                  defaultValue="all"
                />
                Lay
                <label className="form-check-label" htmlFor="lay" />
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="delete"
                  name="filter"
                  defaultValue="all"
                />
                Deleted
                <label className="form-check-label" htmlFor="delete" />
              </div>
            </div>
            <div className="col-md-6 text-end">
              <div>
                Total Bets:{" "}
                <span className="me-2">
                  {data?.betResponseDTOList?.length}
                </span>
                Total Amount:
                <span className="me-2 text-danger">
                  -{data?.totalProfitLoss}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2 table-responsive">
            <table
              role="table"
              className="table table-bordered table-striped"
            >
              <thead>
                <tr role="row">
                  <th colSpan={1} role="columnheader">
                    Nation
                  </th>
                  <th colSpan={1} role="columnheader" className="text-end">
                    Rate
                  </th>
                  <th colSpan={1} role="columnheader" className="text-end">
                    Amount
                  </th>
                  <th colSpan={1} role="columnheader" className="text-end">
                    Win
                  </th>
                  <th colSpan={1} role="columnheader">
                    Date
                  </th>
                  <th colSpan={1} role="columnheader">
                    Ip Address
                  </th>
                  <th colSpan={1} role="columnheader">
                    Browser Details
                  </th>
                  <th colSpan={1} role="columnheader">
                    <div className="text-end">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          title="Toggle All Rows Selected"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                {data?.betResponseDTOList?.map((item) => (
                  <tr role="row" className={item?.backRate ? "back" : "lay"}>
                    <td role="cell">{item?.runnerName}</td>
                    <td role="cell" className="text-end">
                      {item?.backRate ? item?.backRate : item?.layRate}
                    </td>
                    <td role="cell" className="text-end">
                      {item?.amount}
                    </td>
                    <td role="cell" className="text-end">
                      <span
                        role="cell"
                        className={
                          item?.profitLoss > 0
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {item?.profitLoss}
                      </span>
                    </td>
                    <td role="cell">
                      <span role="cell">
                        {moment(item?.createdDate + "Z").format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </span>
                    </td>
                    <td role="cell">{item?.ip}</td>
                    <td role="cell">
                      <u>{item?.userAgent}</u>
                    </td>
                    <td role="cell">
                      <div className="text-end">
                        <div className="form-check form-check-inline" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
