import React from "react";

export default function Teen9({ data }) {
  const resultDTO = data?.casinoIndividualResultDTO || data;

  const casinoIndividualResultMaps =
    resultDTO?.casinoIndividualResultMaps || [];
  const casinoCardResultMaps = resultDTO?.casinoCardResultMaps || [];

  const getResultByType = (resultFor) => {
    const resultObj = casinoIndividualResultMaps.find(
      (item) => item.resultFor === resultFor
    );
    return resultObj ? resultObj.result[0] : "";
  };

  const getCardsByPlayer = (player) => {
    return casinoCardResultMaps.find((item) => item.resultFor === player) || {};
  };

  const matchTime = resultDTO?.createdAt
    ? new Date(resultDTO.createdAt + "Z").toLocaleString()
    : "";

  const players = ["Tiger", "Lion", "Dragon"];

  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className="casino-result-modal">
          <div className="casino-result-round-id">
            <span>
              <b>Round Id: </b> {resultDTO?.marketId || ""}
            </span>
            <span>
              <b>Match Time: </b>
              {matchTime}
            </span>
          </div>
          <div className="row mt-2">
            {players.map((player) => {
              const playerData = getCardsByPlayer(player);
              return (
                <div
                  key={player}
                  className="col-md-4 text-center"
                >
                  <h4 className="result-title">{player}</h4>
                  <div className="casino-result-cards">
                    {playerData.result?.map((card, index) => (
                      <img
                        key={index}
                        src={`https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/${card}.png`}
                        alt={`${player} Card ${index + 1}`}
                      />
                    ))}
                    {playerData.isWinning && (
                      <div className="casino-winner-icon">
                        <i className="fas fa-trophy" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row mt-2 justify-content-center">
            <div className="col-md-6">
              <div className="casino-result-desc">
                <div className="casino-result-desc-item">
                  <div>Winner</div>
                  <div>
                    {casinoCardResultMaps
                      .filter((item) => item.isWinning)
                      .map((item) => item.resultFor)
                      .join(", ")}
                  </div>
                </div>
                <div className="casino-result-desc-item">
                  <div>Others</div>
                  <div>{getResultByType("Others") || "-"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
