import React from "react";

export default function DragonTiger2020({ data }) {
  const { casinoIndividualResultDTO } = data || {};
  const {
    casinoIndividualResultMaps = [],
    casinoCardResultMaps = [],
    marketId,
    createdAt,
  } = casinoIndividualResultDTO || {};

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime + "Z");
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className="casino-result-modal">
          <div className="casino-result-round-id">
            <span>
              <b>Market Id: </b> {marketId || "N/A"}
            </span>
            <span>
              <b>Match Time: </b>{" "}
              {createdAt ? formatDateTime(createdAt) : "N/A"}
            </span>
          </div>
          <div className="row mt-2">
            {casinoCardResultMaps.map((cardResult) => (
              <div className="col-md-6 text-center" key={cardResult.id}>
                <h4 className="result-title">{cardResult.resultFor}</h4>
                <div className="casino-result-cards">
                  {cardResult.isWinning && (
                    <div className="casino-winner-icon">
                      <i className="fas fa-trophy" />
                    </div>
                  )}
                  <img
                    src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${
                      cardResult.result[0]
                    }.png`}
                    alt={cardResult.resultFor}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="row mt-2 justify-content-center">
            <div className="col-md-6">
              <div className="casino-result-desc">
                {casinoIndividualResultMaps.map((result) => (
                  <div className="casino-result-desc-item" key={result.id}>
                    <div>{result.resultFor}</div>
                    <div>{result.result.join(", ")}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
