import React from "react";

export default function TeenpattiOpen({ data }) {
  const resultDTO = data?.casinoIndividualResultDTO || data;

  const casinoIndividualResultMaps =
    resultDTO?.casinoIndividualResultMaps || [];
  const casinoCardResultMaps = resultDTO?.casinoCardResultMaps || [];

  const getResultByType = (resultFor) => {
    const resultObj = casinoIndividualResultMaps.find(
      (item) => item.resultFor === resultFor
    );
    return resultObj ? resultObj.result[0] : "";
  };

  const getCardsByPlayer = (player) => {
    return casinoCardResultMaps.find((item) => item.resultFor === player) || {};
  };

  const matchTime = resultDTO?.createdAt
    ? new Date(resultDTO.createdAt + "Z").toLocaleString()
    : "";

  const getWinners = () => {
    const winningPlayers = casinoCardResultMaps
      .filter((item) => item.isWinning)
      .map((item) => item.resultFor)
      .filter((player) => player !== "Dealer") // Exclude dealer from winners list
      .join(", ");
    return winningPlayers || "-";
  };

  const renderPlayerCards = (player) => (
    <div key={player} className="col-xl-3 text-center">
      <h4 className="result-title">{player}</h4>
      <div className="casino-result-cards">
        {getCardsByPlayer(player).result?.map((card, index) => (
          <img
            key={index}
            src={`https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/${card}.png`}
            alt={`Player ${player} Card ${index + 1}`}
          />
        ))}
        {getCardsByPlayer(player).isWinning && (
          <div className="casino-winner-icon">
            <i className="fas fa-trophy" />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        <div className="casino-result-round-id">
          <span>
            <b>Round Id: </b> {resultDTO?.marketId || ""}
          </span>
          <span>
            <b>Match Time: </b>
            {matchTime}
          </span>
        </div>
        <div className="row mt-2">
          {renderPlayerCards("1")}
          <div className="col-xl-6 text-center">
            <h4 className="result-title">Dealer</h4>
            <div className="casino-result-cards">
              {getCardsByPlayer("Dealer").result?.map((card, index) => (
                <img
                  key={index}
                  src={`https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/${card}.png`}
                  alt={`Dealer Card ${index + 1}`}
                />
              ))}
            </div>
          </div>
          {renderPlayerCards("8")}
        </div>
        <div className="row mt-2">
          {["2", "7"].map((player) => renderPlayerCards(player))}
        </div>
        <div className="row mt-2">
          {["3", "4", "5", "6"].map((player) => renderPlayerCards(player))}
        </div>
        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              <div className="casino-result-desc-item">
                <div>Winner</div>
                <div>{getWinners()}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Pair Plus</div>
                <div>{getResultByType("Pair Plus")}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Total</div>
                <div>{getResultByType("Total")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
