import React from "react";

export default function Queen({ data }) {
  console.log("data", data);
  const casinoResult = data?.casinoIndividualResultDTO;

  if (!casinoResult) {
    return <div>No data available</div>;
  }

  // Helper function to get card images
  const getCardImage = (card) =>
    `https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${card}.png`;

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        {/* Round ID and Match Time */}
        <div className="casino-result-round-id">
          <span>
            <b>Round Id: </b> {casinoResult.marketId}
          </span>
          <span>
            <b>Match Time: </b>
            {new Date(casinoResult.createdAt + "Z").toLocaleString()}
          </span>
        </div>

        {/* Results */}
        <div className="row mt-2">
          {casinoResult.casinoCardResultMaps.map((result, index) => (
            <div key={result.id} className="col-md-3 text-center">
              <h4 className="result-title">
                {result.resultFor} -{" "}
                <span className="badge bg-success">{result.result[2]}</span>
              </h4>
              <div className="casino-result-cards">
                {result.result.slice(0, 2).map((card, idx) => (
                  <img key={idx} src={getCardImage(card)} alt={card} />
                ))}
                {result.isWinning && (
                  <div className="casino-winner-icon">
                    <i className="fas fa-trophy" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Winner Section */}
        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              {casinoResult.casinoIndividualResultMaps.map((winner) => (
                <div key={winner.id} className="casino-result-desc-item">
                  <div>{winner.resultFor}</div>
                  <div>{winner.result[0]}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
