import React from "react";

export default function Race20({ data }) {
  const { casinoIndividualResultDTO } = data || {};
  const { casinoIndividualResultMaps, casinoCardResultMaps } =
    casinoIndividualResultDTO || {};

  // Extract match details
  const matchTime = new Date(
    casinoIndividualResultDTO?.createdAt + "Z"
  ).toLocaleString();
  const roundId = casinoIndividualResultDTO?.marketId;

  // Extract winner, points, and cards
  const winner = casinoIndividualResultMaps?.find(
    (item) => item.resultFor === "Winner"
  )?.result[0];
  const points = casinoIndividualResultMaps?.find(
    (item) => item.resultFor === "Points"
  )?.result[0];
  const cards = casinoIndividualResultMaps?.find(
    (item) => item.resultFor === "Cards"
  )?.result[0];

  // Map suits to their respective icons
  const suits = {
    Spade:
      "https://versionobj.ecoassetsservice.com/v24/static/front/img/icons/spade.png",
    Heart:
      "https://versionobj.ecoassetsservice.com/v24/static/front/img/icons/heart.png",
    Club:
      "https://versionobj.ecoassetsservice.com/v24/static/front/img/icons/club.png",
    Diamond:
      "https://versionobj.ecoassetsservice.com/v24/static/front/img/icons/diamond.png",
  };

  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className="casino-result-modal">
          <div className="casino-result-round-id">
            <span>
              <b>Round Id: </b> {roundId}
            </span>
            <span>
              <b>Match Time: </b> {matchTime}
            </span>
          </div>
          <div className="race-result-box">
            {/* Render cards for each suit */}
            {casinoCardResultMaps?.map((suitData, index) => (
              <div className="casino-result-cards" key={index}>
                <img
                  src={suits[suitData.resultFor]}
                  alt={suitData.resultFor}
                />
                {suitData.result.map(
                  (card, cardIndex) =>
                    card != "1" && (
                      <img
                        key={cardIndex}
                        src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${card}.png`}
                        alt={card}
                      />
                    )
                )}
                {/* Highlight the winning suit with a trophy */}
                {winner?.includes(suitData.resultFor) && (
                  <div className="casino-winner-icon">
                    <i className="fas fa-trophy" />
                  </div>
                )}
              </div>
            ))}
            {/* Display WINNER text */}
            <div className="video-winner-text">
              {"WINNER".split("").map((char, index) => (
                <div key={index}>{char}</div>
              ))}
            </div>
          </div>
          <div className="row mt-2 justify-content-center">
            <div className="col-md-6">
              <div className="casino-result-desc">
                <div className="casino-result-desc-item">
                  <div>Winner</div>
                  <div>{winner}</div>
                </div>
                <div className="casino-result-desc-item">
                  <div>Points</div>
                  <div>{points}</div>
                </div>
                <div className="casino-result-desc-item">
                  <div>Cards</div>
                  <div>{cards}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
