import React, { useState } from "react";

export default function AndarBahar({ data }) {
  // Separate state for each row
  const [currentIndexAndar, setCurrentIndexAndar] = useState(0);
  const [currentIndexBahar, setCurrentIndexBahar] = useState(0);

  // Safely extract dynamic data with fallback values
  const casinoIndividualResultDTO = data?.casinoIndividualResultDTO || {};
  const {
    id = "N/A",
    createdAt = "",
    casinoCardResultMaps = [],
  } = casinoIndividualResultDTO;

  // Combine "Andar" and "Bahar" results
  const andarBaharCards =
    casinoCardResultMaps.map((resultMap) => ({
      title: resultMap.resultFor || "Unknown",
      cards: resultMap.result?.filter((card) => card) || [], // Remove empty cards
    })) || [];

  const maxVisibleCards = 5; // Maximum cards visible at a time
  const cardWidth = 40; // Smaller width to fit more cards
  const sliderWidth = cardWidth * maxVisibleCards + 80;

  // Move slider left or right for Andar or Bahar
  const handleArrowClick = (direction, row) => {
    if (row === "Andar") {
      if (direction === "left" && currentIndexAndar > 0) {
        setCurrentIndexAndar(currentIndexAndar - 1);
      } else if (
        direction === "right" &&
        currentIndexAndar < andarBaharCards[0]?.cards.length - maxVisibleCards
      ) {
        setCurrentIndexAndar(currentIndexAndar + 1);
      }
    } else if (row === "Bahar") {
      if (direction === "left" && currentIndexBahar > 0) {
        setCurrentIndexBahar(currentIndexBahar - 1);
      } else if (
        direction === "right" &&
        currentIndexBahar < andarBaharCards[1]?.cards.length - maxVisibleCards
      ) {
        setCurrentIndexBahar(currentIndexBahar + 1);
      }
    }
  };

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        <div className="casino-result-round-id">
          <span>
            <b>Round Id: </b> {id}
          </span>
          <span>
            <b>Match Time: </b>{" "}
            {createdAt
              ? new Date(createdAt + "Z").toLocaleString()
              : "Unknown"}
          </span>
        </div>

        {andarBaharCards.map((item, index) => {
          // Use separate row indexes for Andar and Bahar
          const currentIndex =
            item.title === "Andar" ? currentIndexAndar : currentIndexBahar;
          const handleArrowClickForRow =
            item.title === "Andar" ? "Andar" : "Bahar";

          return (
            <div key={index} className="row mt-2 ab-result-container">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={() =>
                    handleArrowClick("left", handleArrowClickForRow)
                  }
                  style={{
                    position: "absolute",
                    left: "10px",
                    background: "none",
                    border: "none",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  disabled={currentIndex === 0}
                >
                  &#8592;
                </button>
                <div
                  style={{
                    display: "flex",
                    overflow: "hidden",
                    width: `${sliderWidth}px`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      transform: `translateX(-${currentIndex *
                        cardWidth}px)`,
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    {item.cards.map((card, idx) => (
                      <img
                        key={idx}
                        src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${card}.png`}
                        alt={`Card ${idx + 1}`}
                        style={{
                          width: `${cardWidth}px`,
                          marginRight: "5px",
                          borderRadius: "5px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    ))}
                  </div>
                </div>
                <button
                  onClick={() =>
                    handleArrowClick("right", handleArrowClickForRow)
                  }
                  style={{
                    position: "absolute",
                    right: "10px",
                    background: "none",
                    border: "none",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  disabled={
                    currentIndex >=
                    item.cards.length - maxVisibleCards
                  }
                >
                  &#8594;
                </button>
              </div>
            </div>
          );
        })}

        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              <div className="casino-result-desc-item">
                <div>Winner</div>
                <div>8,7,11,13,9,426,23,32,25,22,21</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
