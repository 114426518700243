import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import CasinoPage from "./components/CasinoPage";
export default function DragonTigerLion() {
  const GAME_ID = "DRAGON_TIGER_LION_20";
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("D");
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );

  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.twentyTwentyDTL}
      GAME_ID={GAME_ID}
      shortName={"dtl20"}
      gameName={"20-20 D T L"}
    >
      <div className="casino-table">
        <div className="casino-table-box d-none d-md-flex">
          <div className="casino-table-left-box">
            <div className="casino-table-header">
              <div className="casino-nation-detail" />
              <div
                className={`casino-odds-box back ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
              >
                Dragon
              </div>
              <div
                className={`casino-odds-box back ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
              >
                Tiger
              </div>
              <div
                className={`casino-odds-box back ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
              >
                Lion
              </div>
            </div>
            <div className="casino-table-body">
              {[
                "Winner",
                `Black`,
                `Red`,
                "Odd",
                "Even",
                "A",
                "2",
                "3",
                "4",
              ]?.map((item, index) => (
                <div className="casino-table-row">
                  <div className="casino-nation-detail">
                    <div className="casino-nation-name">
                      {index > 4 ? null : item}
                      {item == "Black" ? (
                        <span
                          className="casino-odds"
                          style={{ padding: "15px" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-0"
                            data-v-53a3d43f=""
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                              src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                              data-v-53a3d43f=""
                              style={{ width: "18px", height: "18px" }}
                            />
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-0"
                            data-v-53a3d43f=""
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                              src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                              data-v-53a3d43f=""
                              style={{ width: "18px", height: "18px" }}
                            />
                          </span>
                        </span>
                      ) : item == "Red" ? (
                        <span
                          className="casino-odds"
                          style={{ padding: "15px" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-0"
                            data-v-53a3d43f=""
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                              src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                              data-v-53a3d43f=""
                              style={{ width: "18px", height: "18px" }}
                            />
                          </span>{" "}
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-0"
                            data-v-53a3d43f=""
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                              src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                              data-v-53a3d43f=""
                              style={{ width: "18px", height: "18px" }}
                            />
                          </span>
                        </span>
                      ) : index > 4 ? (
                        <img
                          src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() => {
                      setBet(
                        index > 4 ? "Dragon " + item : item + " D",
                        casinoData,
                        dispatch
                      );
                    }}
                  >
                    <span className="casino-odds">
                      {getOddForEntity(
                        index > 4 ? "Dragon " + item : item + " D",
                        casinoData,
                        true
                      )}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      setBet(
                        index > 4 ? "Tiger " + item : item + " T",
                        casinoData,
                        dispatch
                      );
                    }}
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <span className="casino-odds">
                      {" "}
                      {getOddForEntity(
                        index > 4 ? "Tiger " + item : item + " T",
                        casinoData,
                        true
                      )}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      setBet(
                        index > 4 ? "Lion " + item : item + " L",
                        casinoData,
                        dispatch
                      );
                    }}
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <span className="casino-odds">
                      {" "}
                      {getOddForEntity(
                        index > 4 ? "Lion " + item : item + " L",
                        casinoData,
                        true
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="casino-table-right-box">
            <div className="casino-table-header">
              <div className="casino-nation-detail" />
              <div
                className={`casino-odds-box back ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
              >
                Dragon
              </div>
              <div
                className={`casino-odds-box back ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
              >
                Tiger
              </div>
              <div
                className={`casino-odds-box back ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
              >
                Lion
              </div>
            </div>
            <div className="casino-table-body">
              {["5", "6", "7", "8", "9", "10", "J", "Q", "K"]?.map(
                (item, index) => (
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img
                          src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setBet("Dragon " + item, casinoData, dispatch);
                      }}
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">
                        {getOddForEntity("Dragon " + item, casinoData, true)}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setBet("Tiger " + item, casinoData, dispatch);
                      }}
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">
                        {" "}
                        {getOddForEntity("Tiger " + item, casinoData, true)}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setBet("Lion " + item, casinoData, dispatch);
                      }}
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">
                        {" "}
                        {getOddForEntity("Lion " + item, casinoData, true)}
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <ul className="d-xl-none nav nav-pills" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id="uncontrolled-tab-example-tab-0"
              role="tab"
              data-rr-ui-event-key={0}
              aria-controls="uncontrolled-tab-example-tabpane-0"
              aria-selected="true"
              className={`nav-link ${selectedType === "D" ? "active" : ""}`}
              onClick={() => setSelectedType("D")}
            >
              Dragon
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id="uncontrolled-tab-example-tab-1"
              role="tab"
              data-rr-ui-event-key={1}
              aria-controls="uncontrolled-tab-example-tabpane-1"
              aria-selected="false"
              tabIndex={-1}
              className={`nav-link ${selectedType === "T" ? "active" : ""}`}
              onClick={() => setSelectedType("T")}
            >
              Tiger
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id="uncontrolled-tab-example-tab-2"
              role="tab"
              data-rr-ui-event-key={2}
              aria-controls="uncontrolled-tab-example-tabpane-2"
              aria-selected="false"
              tabIndex={-1}
              className={`nav-link ${selectedType === "L" ? "active" : ""}`}
              onClick={() => setSelectedType("L")}
            >
              Lion
            </button>
          </li>
        </ul>
        <div className="tab-content">
          <div
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-0"
            aria-labelledby="uncontrolled-tab-example-tab-0"
            className="fade d-xl-none tab-pane active show"
          >
            <div className="casino-table-box">
              <div className="casino-table-left-box">
                <div className="casino-table-body">
                  {[
                    "Winner",
                    "Black",
                    "Red",
                    "Odd",
                    "Even",
                    "A",
                    "2",
                    "3",
                    "4",
                  ].map((item, index) => (
                    <div className="casino-table-row">
                      <div className="casino-nation-detail">
                        <div className="casino-nation-name">
                          {index > 4 ? (
                            <img
                              src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                            />
                          ) : (
                            item
                          )}
                        </div>
                      </div>
                      <div
                        className={`casino-odds-box back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        }`}
                        onClick={() => {
                          setBet(
                            index > 4
                              ? selectedType == "D"
                                ? "Dragon " + item
                                : selectedType == "T"
                                ? "Tiger " + item
                                : "Lion " + item
                              : item + " " + selectedType,
                            casinoData,
                            dispatch
                          );
                        }}
                      >
                        <span className="casino-odds">
                          {getOddForEntity(
                            index > 4
                              ? selectedType == "D"
                                ? "Dragon " + item
                                : selectedType == "T"
                                ? "Tiger " + item
                                : "Lion " + item
                              : item + " " + selectedType,
                            casinoData,
                            true
                          )}
                          <br />
                          {getCasinoPlExposureByRunner(
                            casinoData,
                            index > 4
                              ? selectedType == "D"
                                ? "Dragon " + item
                                : selectedType == "T"
                                ? "Tiger " + item
                                : "Lion " + item
                              : item + " " + selectedType
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="casino-table-right-box">
                <div className="casino-table-body">
                  {["5", "6", "7", "8", "9", "10", "J", "Q", "K"].map(
                    (item, index) => (
                      <div className="casino-table-row">
                        <div className="casino-nation-detail">
                          <div className="casino-nation-name">
                            {
                              <img
                                src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                              />
                            }
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setBet(
                              selectedType == "D"
                                ? "Dragon " + item
                                : selectedType == "T"
                                ? "Tiger " + item
                                : "Lion " + item,
                              casinoData,
                              dispatch
                            );
                          }}
                          className={`casino-odds-box back ${
                            casinoData?.data?.response?.autoTime < 5
                              ? "suspended-box"
                              : ""
                          }`}
                        >
                          <span className="casino-odds">
                            {getOddForEntity(
                              selectedType == "D"
                                ? "Dragon " + item
                                : selectedType == "T"
                                ? "Tiger " + item
                                : "Lion " + item,
                              casinoData,
                              true
                            )}
                            <br />
                            {getCasinoPlExposureByRunner(
                              casinoData,
                              selectedType == "D"
                                ? "Dragon " + item
                                : selectedType == "T"
                                ? "Tiger " + item
                                : "Lion " + item
                            )}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-1"
            aria-labelledby="uncontrolled-tab-example-tab-1"
            className="fade d-xl-none tab-pane"
          >
            <div className="casino-table-box">
              <div className="casino-table-left-box">
                <div className="casino-table-body">
                  {[
                    "Winner",
                    `Black`,
                    `Red`,
                    "Odd",
                    "Even",
                    "A",
                    "2",
                    "3",
                    "4",
                  ]?.map((item, index) => (
                    <div className="casino-table-row">
                      <div className="casino-nation-detail">
                        <div className="casino-nation-name">
                          {index > 4 ? null : item}
                          {item == "Black" ? (
                            <span
                              className="casino-odds"
                              style={{ padding: "15px" }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                                data-v-53a3d43f=""
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                                  data-v-53a3d43f=""
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                                data-v-53a3d43f=""
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                                  data-v-53a3d43f=""
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </span>
                            </span>
                          ) : item == "Red" ? (
                            <span
                              className="casino-odds"
                              style={{ padding: "15px" }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                                data-v-53a3d43f=""
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                                  data-v-53a3d43f=""
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </span>{" "}
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                                data-v-53a3d43f=""
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                                  data-v-53a3d43f=""
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </span>
                            </span>
                          ) : index > 4 ? (
                            <img
                              src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={`casino-odds-box back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        }`}
                      >
                        <span className="casino-odds">
                          {getOddForEntity(
                            index > 4
                              ? selectedType == "D"
                                ? "Dragon "
                                : "" + item
                              : item + " " + selectedType,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                      <div
                        className={`casino-odds-box back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        }`}
                      >
                        <span className="casino-odds">
                          {" "}
                          {getOddForEntity(
                            index > 4
                              ? selectedType == "T"
                                ? "Tiger "
                                : "" + item
                              : item + " " + selectedType,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                      <div
                        className={`casino-odds-box back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        }`}
                      >
                        <span className="casino-odds">
                          {" "}
                          {getOddForEntity(
                            index > 4
                              ? selectedType == "L"
                                ? "Lion "
                                : "" + item
                              : item + " " + selectedType,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="casino-table-right-box">
                <div className="casino-table-body">
                  {["5", "6", "7", "8", "9", "10", "J", "Q", "K"].map(
                    (item, index) => (
                      <div className="casino-table-row">
                        <div className="casino-nation-detail">
                          <div className="casino-nation-name">
                            {
                              <img
                                src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                              />
                            }
                          </div>
                        </div>
                        <div
                          className={`casino-odds-box back ${
                            casinoData?.data?.response?.autoTime < 5
                              ? "suspended-box"
                              : ""
                          }`}
                        >
                          <span className="casino-odds">
                            {getOddForEntity(
                              item + " " + selectedType == "D"
                                ? "Dragon"
                                : selectedType == "T"
                                ? "Tiger"
                                : "Lion",
                              casinoData,
                              true
                            )}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-2"
            aria-labelledby="uncontrolled-tab-example-tab-2"
            className="fade d-xl-none tab-pane"
          >
            <div className="casino-table-box">
              <div className="casino-table-left-box">
                <div className="casino-table-body">
                  {[
                    "Winner",
                    `Black`,
                    `Red`,
                    "Odd",
                    "Even",
                    "A",
                    "2",
                    "3",
                    "4",
                  ]?.map((item, index) => (
                    <div className="casino-table-row">
                      <div className="casino-nation-detail">
                        <div className="casino-nation-name">
                          {index > 4 ? null : item}
                          {item == "Black" ? (
                            <span
                              className="casino-odds"
                              style={{ padding: "15px" }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                                data-v-53a3d43f=""
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                                  data-v-53a3d43f=""
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                                data-v-53a3d43f=""
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                                  data-v-53a3d43f=""
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </span>
                            </span>
                          ) : item == "Red" ? (
                            <span
                              className="casino-odds"
                              style={{ padding: "15px" }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                                data-v-53a3d43f=""
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                                  data-v-53a3d43f=""
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </span>{" "}
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                                data-v-53a3d43f=""
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                                  data-v-53a3d43f=""
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </span>
                            </span>
                          ) : index > 4 ? (
                            <img
                              src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={`casino-odds-box back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        }`}
                      >
                        <span className="casino-odds">
                          {getOddForEntity(
                            index > 4 ? "Dragon " + item : item + " D",
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                      <div
                        className={`casino-odds-box back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        }`}
                      >
                        <span className="casino-odds">
                          {" "}
                          {getOddForEntity(
                            index > 4 ? "Tiger " + item : item + " T",
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                      <div
                        className={`casino-odds-box back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        }`}
                      >
                        <span className="casino-odds">
                          {" "}
                          {getOddForEntity(
                            index > 4 ? "Lion " + item : item + " L",
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="casino-table-right-box">
                <div className="casino-table-body">
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/5.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/6.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/7.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/8.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/9.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/10.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/J.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/Q.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/K.png" />
                      </div>
                    </div>
                    <div
                      className={`casino-odds-box back ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                    >
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
