import React from "react";

export default function SuperOver({ data }) {
  const { casinoCricketResultDTO, casinoIndividualResultDTO } = data || {};
  const { team1, team2 } = casinoCricketResultDTO || {};
  const { casinoIndividualResultMaps } = casinoIndividualResultDTO || {};

  // Extract winner and scores
  const winner = casinoIndividualResultMaps?.find(
    (item) => item.resultFor === "Winner"
  )?.result[0];
  const team1Score = casinoIndividualResultMaps?.find(
    (item) => item.resultFor === "ENG"
  )?.result[0];
  const team2Score = casinoIndividualResultMaps?.find(
    (item) => item.resultFor === "RSA"
  )?.result[0];

  // Match time and round ID
  const matchTime = new Date(
    casinoIndividualResultDTO?.createdAt + "Z"
  ).toLocaleString();
  const roundId = casinoIndividualResultDTO?.marketId;

  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className="casino-result-modal">
          <div className="casino-result-round-id">
            <span>
              <b>Round Id: </b> {roundId}
            </span>
            <span>
              <b>Match Time: </b> {matchTime}
            </span>
          </div>
          <div className="five-cricket-result">
            <div className="mt-2">
              <div className="text-end score-head">
                Winner:<span className="text-fancy"> {winner}</span> | ENG :{" "}
                {team1Score} | RSA : {team2Score}
              </div>
            </div>
            <div className="mt-2">
              <h4>First Inning</h4>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ENG</th>
                      <th className="text-center">1</th>
                      <th className="text-center">2</th>
                      <th className="text-center">3</th>
                      <th className="text-center">4</th>
                      <th className="text-center">5</th>
                      <th className="text-center">6</th>
                      <th className="text-center">Run/Over</th>
                      <th className="text-center">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {team1?.map((inning, index) => (
                      <tr key={index}>
                        <td>{inning.over}</td>
                        <td className="text-center">{inning.run1}</td>
                        <td className="text-center">{inning.run2}</td>
                        <td className="text-center">{inning.run3}</td>
                        <td className="text-center">{inning.run4}</td>
                        <td className="text-center">{inning.run5}</td>
                        <td className="text-center">{inning.run6}</td>
                        <td className="text-center">{inning.runsPerOver}</td>
                        <td className="text-center">{inning.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-2">
              <h4>Second Inning</h4>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>RSA</th>
                      <th className="text-center">1</th>
                      <th className="text-center">2</th>
                      <th className="text-center">3</th>
                      <th className="text-center">4</th>
                      <th className="text-center">5</th>
                      <th className="text-center">6</th>
                      <th className="text-center">Run/Over</th>
                      <th className="text-center">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {team2?.map((inning, index) => (
                      <tr key={index}>
                        <td>{inning.over}</td>
                        <td className="text-center">{inning.run1}</td>
                        <td className="text-center">{inning.run2}</td>
                        <td className="text-center">{inning.run3}</td>
                        <td className="text-center">{inning.run4}</td>
                        <td className="text-center">{inning.run5}</td>
                        <td className="text-center">{inning.run6}</td>
                        <td className="text-center">{inning.runsPerOver}</td>
                        <td className="text-center">{inning.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
