import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import PlaceBet from "./components/PlaceBet";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import Timer from "./components/Timer";
import CasinoVideo from "./components/CasinoVideo";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import CasinoPage from "./components/CasinoPage";
export default function TeenpattiOneDay() {
  const GAME_ID = "TEEN";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.teenPatti1Day}
      GAME_ID={GAME_ID}
      shortName={"teenpatti1day"}
      gameName={"1 Day Teen Patti"}
    >
      <div className="casino-detail">
        <div className="casino-table">
          <div className="casino-table-box">
            <div className="casino-table-left-box">
              <div className="casino-table-header">
                <div className="casino-nation-detail">Player A</div>
                <div className="casino-odds-box back">Back</div>
                <div className="casino-odds-box lay">Lay</div>
              </div>
              <div className="casino-table-body">
                <div
                  className={`casino-table-row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div className="casino-nation-detail">
                    <div className="casino-nation-name">Main</div>
                  </div>
                  <div onClick={
                    () => {
                      setBet("Player A Main", casinoData, dispatch);
                    }
                  } className="casino-odds-box back">
                    <span className="casino-odds">
                      {getOddForEntity("Player A Main", casinoData, true)}
                    </span>
                  </div>
                  <div onClick={
                    () => {
                      setBet("Player A Main", casinoData, dispatch);
                    }
                  } className="casino-odds-box lay">
                    <span className="casino-odds">
                      {getOddForEntity("Player A Main", casinoData, false)}
                    </span>
                  </div>
                </div>
                <div
                  className={`casino-table-row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div className="casino-nation-detail">
                    <div className="casino-nation-name">Consecutive</div>
                  </div>
                  <div onClick={
                    () => {
                      setBet("Player A Consecutive", casinoData, dispatch);
                    }
                  } className="casino-odds-box back">
                    <span className="casino-odds">
                      {" "}
                      {getOddForEntity(
                        "Player A Consecutive",
                        casinoData,
                        true
                      )}
                    </span>
                  </div>
                  <div onClick={
                    () => {
                      setBet("Player A Consecutive", casinoData, dispatch);
                    }
                  } className="casino-odds-box lay">
                    <span className="casino-odds">
                      {" "}
                      {getOddForEntity(
                        "Player A Consecutive",
                        casinoData,
                        false
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="casino-table-box-divider" />
            <div className="casino-table-right-box">
              <div className="casino-table-header">
                <div className="casino-nation-detail">Player B</div>
                <div className="casino-odds-box back">Back</div>
                <div className="casino-odds-box lay">Lay</div>
              </div>
              <div className="casino-table-body">
                <div
                  className={`casino-table-row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div className="casino-nation-detail">
                    <div className="casino-nation-name">Main</div>
                  </div>
                  <div onClick={
                    () => {
                      setBet("Player B Main", casinoData, dispatch);
                    }
                  } className="casino-odds-box back">
                    <span className="casino-odds">
                      {getOddForEntity("Player B Main", casinoData, true)}
                    </span>
                  </div>
                  <div onClick={
                    () => {
                      setBet("Player B Main", casinoData, dispatch);
                    }
                  } className="casino-odds-box lay">
                    <span className="casino-odds">
                      {getOddForEntity("Player B Main", casinoData, false)}
                    </span>
                  </div>
                </div>
                <div
                  className={`casino-table-row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div className="casino-nation-detail">
                    <div className="casino-nation-name">Consecutive</div>
                  </div>
                  <div
                    onClick={() => {
                      setBet("Player B Consecutive", casinoData, dispatch);
                    }}
                    className="casino-odds-box back"
                  >
                    <span className="casino-odds">
                      {getOddForEntity(
                        "Player B Consecutive",
                        casinoData,
                        true
                      )}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      setBet("Player B Consecutive", casinoData, dispatch);
                    }}
                    className="casino-odds-box lay"
                  >
                    <span className="casino-odds">
                      {getOddForEntity(
                        "Player B Consecutive",
                        casinoData,
                        false
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="casino-table-full-box teenpatti1day-other-odds mt-3">
            <div className="casino-table-header">
              <div className="casino-nation-detail" />
              <div className="casino-odds-box">Card 1</div>
              <div className="casino-odds-box">Card 2</div>
              <div className="casino-odds-box">Card 3</div>
              <div className="casino-odds-box">Card 4</div>
              <div className="casino-odds-box">Card 5</div>
              <div className="casino-odds-box">Card 6</div>
            </div>
            <div className="casino-table-body">
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">Odd</div>
                </div>
                {[1, 2, 3, 4, 5, 6].map((item, index) => (
                  <div
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() => {
                      setBet(`Card ${item} Odd`, casinoData, dispatch);
                    }}
                  >
                    <span className="casino-odds">
                      {getOddForEntity(`Card ${item} Odd`, casinoData, true)}
                    </span>
                  </div>
                ))}
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">Even</div>
                </div>
                {[1, 2, 3, 4, 5, 6].map((item, index) => (
                  <div
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() => {
                      setBet(`Card ${item} Even`, casinoData, dispatch);
                    }}
                  >
                    <span className="casino-odds">
                      {getOddForEntity(`Card ${item} Even`, casinoData, true)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="casino-remark mt-1">
            <marquee scrollamount={3}> </marquee>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
